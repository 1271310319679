
import { Component, Vue } from 'vue-property-decorator';
import { Action, State } from "vuex-class";

@Component({})
export default class NotificationBanner extends Vue {

    public timeout: any;

    @State('auth')
    public auth: any;

    @Action('healthCheck', { namespace: 'auth' })
    public healthCheck: any;

    public async mounted() {
        this.refreshStatsPeriodically();
    }

    /**
     * Refresh the stats every period of time.
     */
    public refreshStatsPeriodically() {
        Promise.all([
            this.healthCheck()
        ]).then(() => {
            this.timeout = setTimeout(() => {
                this.refreshStatsPeriodically();
            }, 1000 * 15);
        });
    }

    /**
     * Clean after the component is destroyed.
     */
    public destroyed() {
        clearTimeout(this.timeout);
    }
}
