import axios from 'axios';

export const baseApi = axios.create({
    baseURL: '/',
})

const client = axios.create({
    baseURL: process.env.VUE_APP_AUTH_API_URL || '/v1',
});

export default client;
